import {validDesktopBrowser} from '../config.js';
import {browserErrorMessage} from '../shared/browserErrorMessage.js';
import {app} from './app.js';
import {body} from '../shared/body.js';
import {loadPage} from '../shared/loadPage.js';

if (validDesktopBrowser) app.init();

(async () => {
	await loadPage.init({showLoader: false});
	if (!validDesktopBrowser) return browserErrorMessage.init();
	body.resetScrollPosition();
})();