import {S3_PATH} from '../config.js';
import {
	map,
	getRandomInteger,
	getRandomFloat,
	fpsToMilliseconds
} from '../shared/math.js';
import {delay, getRandomElmFromArray} from '../shared/util.js';
import {overlayPreventClick} from '../shared/overlayPreventClick.js';
import {checkImage} from '../shared/loadImages.js';

// Container
const container = (function(){

	// States
	let initialTargetAngle = true;
	let targetAngleSet = false;

	// Options
	const renderFilters = false;
	const filterParamSpeed = 50;
	const frameRate = 12;
	const speedParamAmount = .5;

	// Data
	let currentElm;
	const defaultfantascope = {
		mediaId: 'logo-image',
		title: "Spoolyard",
		fps: 6
	};
	const moreAngleOptions = [3,-3]
	const filters = [
		{
			name: "blur",
			type: "px",
			min: 0,
			max: 5,
			amount: 0.2,
			target: 0,
			use: 0
		},
		{
			name: "contrast",
			type: "%",
			min: 300,
			max: 400,
			amount: 0.2,
			target: 0,
			use: 300
		}
	];
	const angle = {
		increase: 0,
		target: null,
		use: 0
	}

	//cache DOM
	const $el = $('#container');
	const $imgParent = $el.find('#img-parent');
	const $exploreBtn = $el.find('#explore-button');
	const $createBtn = $el.find('#create-button');
	let $img;
	let img;

	function cacheDOM(){
		$img = $imgParent.find('img');
		img = $img[0];
	}

	//bind events
	$exploreBtn.on('click', () => goToLink('/explore'));
	$createBtn.on('click', () => goToLink('/create'));

	async function init(){
		currentElm = defaultfantascope;
		console.log("currentElm", currentElm);
		const animationAngle = 360/currentElm.fps;
		// Add calculated and global angle options
		currentElm.angleOptions = [
			...moreAngleOptions,
			animationAngle,
			-animationAngle
		]		
		console.log("currentElm.angleOptions", currentElm.angleOptions);
		// Add initial angle options
		currentElm.initialAngleOptions = [
			animationAngle,
			-animationAngle
		]
		angle.target = getRandomElmFromArray(currentElm.initialAngleOptions);
		createElement(currentElm, $imgParent);
		cacheDOM();
		// Wait for image to load
		try {
			const loadedImage = await checkImage(img);
			console.log('Image found and loaded', loadedImage);
		} catch(err){
			console.log(err);
			return;
		}
		show();
		overlayPreventClick.fadeOut();
		await delay(1000);
		setInterval(speed, fpsToMilliseconds(frameRate));
		if (renderFilters){
			setRandomFilterTarget();
			await delay(3000);
			initFilter();
			setInterval(initFilter, filterParamSpeed);
			setInterval(setRandomFilterTarget, 5000);
		}
	}

	function createElement(element, container){
		// Set media path
		const mediaPath = `${S3_PATH}/media/enter/${element.mediaId}.jpg`;
		const str = 
			`
			<img src="${mediaPath}" alt="${element.title}">
			`
		// Append to DOM
		container.prepend(str);
	}

	function show(){
		$el.show();
	}

	function setRandomAngleTarget(){
		angle.target = getRandomElmFromArray(currentElm.angleOptions);
		//console.log("angle.target", angle.target)
		targetAngleSet = false;
		console.log("targetAngleSet", targetAngleSet);
	}

	function setRandomFilterTarget(){
		filters.forEach((obj, index) => {
			obj.target = getRandomFloat(obj.min, obj.max);
		});
	}

	function initFilter(){
		filters.forEach((obj, index) => {
			setFilter(index);
		});
	}

	function setFilter(i){
		if (filters[i].target == filters[i].use) return;
		const amount = filters[i].target > filters[i].use ? filters[i].amount : -filters[i].amount;
		filters[i].use += amount;
		applyFilter();
	}

	function applyFilter(){
		$img.css("filter",
			`
			${filters[0].name}(${Math.abs(filters[0].use)}${filters[0].type})
			${filters[1].name}(${Math.abs(filters[1].use)}${filters[1].type})
			`
		);
	}

	async function speed(){
		let amount = speedParamAmount;
		// Logarithmic acceleration and deceleration at low speeds
		if (angle.increase > -3 && angle.increase < 3){
			if (angle.increase < 0) amount = map(angle.increase, 0, -3, 0.01, speedParamAmount)
			if (angle.increase >= 0) amount = map(angle.increase, 0, 3, 0.01, speedParamAmount)
		}
		if (angle.increase > angle.target) angle.increase -= amount;
		if (angle.increase < angle.target) angle.increase += amount;
		angle.use += angle.increase;
		//console.log("Math.round(angle.increase)", Math.round(angle.increase))
		//console.log("Math.round(angle.target)", Math.round(angle.target))
		if (Math.round(angle.increase) == Math.round(angle.target) && !targetAngleSet){
			targetAngleSet = true;
			console.log("targetAngleSet", targetAngleSet);
			const delays = [2000, getRandomInteger(2000, 7000)];
			const delayUse = initialTargetAngle ? 5000 : getRandomElmFromArray(delays);
			console.log("delayUse", delayUse);
			setTimeout(setRandomAngleTarget, delayUse);
			if (initialTargetAngle) initialTargetAngle = false;
			//console.log("initialTargetAngle", initialTargetAngle);
		}
		rotate($img, angle.use);
	}

	function rotate($elm, degrees){
		//console.log("angleuse", degrees);
		$elm.css('transform', `rotate(${degrees}deg)`);
	}

	function goToLink(url){
		window.location.href = url;
	}

	return {
		init: init
	};

})(); //container

export {container};