const onChange = require('on-change');
import {recordPlayer} from './config.js';
import {userRequests} from './shared/userRequests.js';

// Note: mobile route has dedicated states
// Note: export mode functions as a sub-state of screen mode

// Initial states values
const states = {
	socketRoomCode: null,
	remotePaired: false,
	recordPlayerMode: recordPlayer,
	enableParametersChange: true,
	soundReady: false,
	mediaHasAudio: false,
	fantascope: false,
	documentHidden: false,
	initialSelection: false,
	initialRotation: false,
	initialPhoto: false,
	initialParamChange: false,
	optionSelected: false,
	auth: false,
	pageId: null,
	userOwnsPost: false,
	multipleParams: false,
	screenMode: false,
	screenModeSettings: {},
	fullscreen: false,
	createMode: false,
	createPostCreated: false,
	hidePanels: false,
	menuOpen: false,
	mediaId: null,
	lastFrame: 0,
	audio: false,
	info: false,
	soundFX: false,
	waveform: false,
	playing: false,
	tour: false,
	postEditFormOpen: false,
	postExportPanelOpen: false,
	sharePanelOpen: false,
	remixPanelOpen: false,
	reportPanelOpen: false,
	keyframesToSave: [],
	saved: true,
	userChangedKeyframes: false,
	exportMode: false,
	exportAudioMode: false,
	exportSettings: {},
	mediaData: {},
	width: null,
	height: null,
	user: null,
	proUser: false,
	numberOfDesktopSocketsInRoom: 0,
	enableImageMove: false,
	enableImageZoom: false,
	userLikesPost: false,
	currentStorage: 0,
	history: []
};

// Set states based on meta tags
states.auth = $('meta[name="auth"]').attr('content') ? true : false;
states.pageId = $('meta[name="page-id"]').attr('content');

async function getUserInfo(){
	if (!states.auth) return;
	const userResults = await userRequests.userInfo();
	console.log("userResults", userResults);
	states.user = userResults;
	// Check if user is pro
	states.proUser = states.user.user_metadata?.subscriptionInfo?.status == 'active'
	console.log("states.proUser", states.proUser);
	// Redirect to error page if user email is not verified
	const pathname = window.location.pathname;
	const allowedPaths = [
		"email-verification-error",
		"explore",
		"settings",
		"about",
		"faq",
		"terms-of-service",
		"terms",
		"tos",
		"privacy-policy",
		"privacy"
	];
	if (!stringIncludesAny(pathname, allowedPaths) && !states.user.email_verified) window.location.replace("/email-verification-error");
}

function stringIncludesAny(str, arr) {
  return arr.some(substring => str.includes(substring));
}

// Initial store values
let store = {
	user: null
}

// If states exist in local storage, update store
getSavedStates();

// When savedStates is changed, update store and save to local storage. Store object is being watched by savedStates variable as a proxy.
const savedStates = onChange(store, () => setSavedStates(store));

function setSavedStates(store){
	localStorage.setItem('states', JSON.stringify(store));
}

function getSavedStates(){
	if (!localStorage.getItem('states')) return;
	store = JSON.parse(localStorage.getItem('states'));
	//console.log("store", store);
}

function clearStates(){
	localStorage.removeItem('states');
}

function unsubscribe(){
	onChange.unsubscribe(savedStates);
}

export {
	states,
	savedStates,
	getUserInfo
};