// Body
const body = (function(){

	//cache DOM
	const $el = $('body');

	function resetScrollPosition(){
		window.scrollTo(0, 0);
	}

	function enableScroll(){
		//$el.css("overflow-y", "auto");
		$el.css("overflow-y", "scroll");
	}

	function disableScroll(){
		$el.css("overflow-y", "hidden");
	}

	function whiteBackground(){
		$el.addClass('white-background');
	}

	return {
		enableScroll: enableScroll,
		disableScroll: disableScroll,
		resetScrollPosition: resetScrollPosition,
		whiteBackground: whiteBackground
	};

})(); //body

export {body};