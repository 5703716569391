import {S3_PATH} from '../config.js';

const loadImagesFromS3 = (images) => {
	// Add S3 bucket path to file name and set image source
	images.each(function(){
		const fileName = $(this).attr('data-image-src');
		if (!fileName) return;
		$(this).attr("crossorigin", "anonymous");
		$(this).attr("src", `${S3_PATH}/${fileName}`);
	});
	console.log("S3 paths set");
}

// Source: https://stackoverflow.com/questions/9815762/detect-when-an-image-fails-to-load-in-javascript
function checkImage(image) {
	const imgPromise = new Promise((resolve, reject) => {
		image.addEventListener('load', function imgOnLoad() {
			resolve(this);
		});
		image.addEventListener('error', function imgOnError() {
			reject('Image not found');
		})
	});
	return imgPromise;
}

export {
	loadImagesFromS3,
	checkImage
}