// Map number ranges
// Source: https://gist.github.com/xposedbones/75ebaef3c10060a3ee3b246166caab56
/*Number.prototype.map = function (in_min, in_max, out_min, out_max) {
	return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
};*/
// One line:
const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;

// Subtraction
const subtract = (num1, num2) => num1-num2;

// Difference
function difference(a, b) {
  return Math.abs(a-b)
}

// Clamp
const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

// Is between
const isBetween = (value, num1, num2) => value > num1 && value < num2;

// Return random integer
// Source: https://stackoverflow.com/questions/1527803/generating-random-whole-numbers-in-javascript-in-a-specific-range
function getRandomInteger(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Return random float
function getRandomFloat(min, max) {
	return Math.random() * (max - min) + min;
}

// FPS to milliseconds
// Source: https://medium.com/@rishabhsrao/your-eyes-your-computer-screen-your-browser-and-the-javascript-event-loop-7ae1d306cf47
// Formula: 1 frame * 1000ms / frame rate = render cycle
function fpsToMilliseconds(fps){
	return 1000/fps;
}

export {
	map,
	subtract,
	difference,
	clamp,
	isBetween,
	getRandomInteger,
	getRandomFloat,
	fpsToMilliseconds
}