import {callFetch} from './fetchAPI';

// User requests
const userRequests = (function(){

	function userInfo() {
		return callFetch({
			url: '/users/me',
			status: 200
		})
	}

	function updateUser(obj) {
		return callFetch({
			url: '/users/me',
			method: 'PATCH',
			data: obj,
			status: 200
		})
	}

	function deleteUser() {
		return callFetch({
			url: '/users/me',
			method: 'DELETE',
			status: 200
		})
	}

	function resetPassword(user) {
		return callFetch({
			url: '/users/password',
			method: 'POST',
			data: user,
			status: 200
		})
	}

	function getSocketCode() {
		return callFetch({
			url: '/users/socketcode',
			status: 200
		})
	}

	function checkUserRole(role) {
		return callFetch({
			url: `/users/check-role/${role}`,
			status: 200
		})
	}

	function getRoles() {
		return callFetch({
			url: '/users/get-roles',
			status: 200
		})
	}

	return {
		userInfo: userInfo,
		updateUser: updateUser,
		deleteUser: deleteUser,
		resetPassword: resetPassword,
		getSocketCode: getSocketCode,
		checkUserRole: checkUserRole,
		getRoles: getRoles
	};

})(); //userRequests

export {userRequests};
