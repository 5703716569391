// Load page
const loadPage = (function(){

	//options
	const minLoadTime = 500;

	function init({showLoader = false} = {}){
		return new Promise(resolve => {
			window.addEventListener('load', () => {
				loaded(showLoader, resolve);
			});
		})
	}

	function loaded(showLoader, resolve){
		console.log("Window loaded");
		if (!showLoader) return resolve();
		setTimeout(() => {
			$(".pace").addClass("pace-inactive");
			resolve();
		}, minLoadTime);
	}

	return {
		init: init
	}

})(); //loadPage

export {loadPage};