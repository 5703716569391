import {overlayPreventClick} from './overlayPreventClick.js';

// Browser error message
const browserErrorMessage = (function(){

	//options
	const desktopAttemptMsg = "Please use Chrome or Firefox on a desktop browser";
	const mobileAttemptMsg = "Please use Safari or Chrome on a mobile browser";

	//cache DOM
	const $el = $('#browser-error-message');
	const $p = $el.find('p');

	function init({mobileAttempt} = {}){
		mobileAttempt ? $p.html(mobileAttemptMsg) : $p.html(desktopAttemptMsg);
		$el.fadeIn();
		overlayPreventClick.fadeOut();
	}

	return {
		init: init
	};

})(); //browserErrorMessage

export {browserErrorMessage};