const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const getRandomElmFromArray = items => items[Math.floor(Math.random() * items.length)];

const bytesToMegaBytes = bytes => bytes / (1024*1024);

const shuffleArray = array => array.sort(() => Math.random() - 0.5);

// Source: https://www.joshwcomeau.com/snippets/javascript/debounce/
const debounce = (callback, wait) => {
	let timeoutId = null;
	return (...args) => {
		window.clearTimeout(timeoutId);
		timeoutId = window.setTimeout(() => {
			callback.apply(null, args);
		}, wait);
	};
}

const getPercentage = (part, whole) => {
	if (whole === 0) return 0;
	return (part / whole) * 100;
};

const megabytesToGigabytes = (megabytes) => {
	const gigabytes = megabytes / 1024;
	return gigabytes;
}

const shallowObjEqual = (obj1, obj2) => {
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);
	if (keys1.length !== keys2.length) return false;
	return keys1.every(key => obj1[key] === obj2[key]);
}

export {
	delay,
	getRandomElmFromArray,
	bytesToMegaBytes,
	shuffleArray,
	debounce,
	getPercentage,
	megabytesToGigabytes,
	shallowObjEqual
};