// Libraries
const bowser = require('bowser');

// Domain name
const domainName = window.location.hostname.split(".")[0]

// Mode
const productionMode = process.env.PRODUCTION == "false" ? false : true;
const stagingMode = domainName == "vmip-staging" ? true : false;

// Options
const recordPlayer = false;
const localMedia = productionMode ? false : true;

// Root URL
const rootURL = productionMode
? `https://${window.location.hostname}`
: `${process.env.ROOT_URL}:${process.env.PORT}`;

// Local server URL
const localServerURL = !productionMode ? `https://localhost:${process.env.PORT}` : false;

// Mobile device
const mobileDevice = bowser.mobile || bowser.tablet ? true : false;
console.log("mobileDevice", mobileDevice);

// Valid desktop browser
const validDesktopBrowser = !mobileDevice && (bowser.chrome || bowser.firefox) ? true : false;
console.log("validDesktopBrowser", validDesktopBrowser);

// Valid mobile browser
const validMobileBrowser = mobileDevice ? true : false;
console.log("validMobileBrowser", validMobileBrowser);

// S3 Path
const S3_PATH = localMedia
? ""
: process.env.S3_PATH || "https://s3.amazonaws.com/vmip";
const S3_PATH_UPLOAD = process.env.S3_PATH_UPLOAD || (stagingMode ? "https://s3.amazonaws.com/vmip-upload-dev" : "https://s3.amazonaws.com/vmip-upload")
const S3_PATH_EXPORT = process.env.S3_PATH_EXPORT || (stagingMode ? "https://s3.amazonaws.com/vmip-export-dev" : "https://s3.amazonaws.com/vmip-export")

export {
	recordPlayer,
	productionMode,
	rootURL,
	localServerURL,
	domainName,
	mobileDevice,
	S3_PATH,
	S3_PATH_UPLOAD,
	S3_PATH_EXPORT,
	validDesktopBrowser,
	validMobileBrowser
};