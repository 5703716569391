import {states} from '../states.js';
import {loadImagesFromS3} from '../shared/loadImages';
import {container} from './container.js';

// App
const app = (function(){

	//cache DOM
	const $el = $('#app');
	const $images = $el.find('img');

	function init(){
		loadImagesFromS3($images);
		container.init();
	}

	return {
		init: init
	};

})(); //app

export {app};